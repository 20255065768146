import React, { useState } from "react"
import {
  Box,
  Container,
  Text,
  Heading,
  Grid,
  Input,
  Button,
  Flex,
  Label,
} from "@theme-ui/components"
import ArrowRight from "../../assets/images/arrow-right.svg"
import { Textarea, Checkbox } from "theme-ui"
import { InboundLink } from "../link"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"

const ApplicationForm = ({ title, subtitle, description, summary }) => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const isBrowser = typeof window !== "undefined"

  const [formData, setFormData] = useState({
    email: "",
    nome: "",
    cognome: "",
    telefono: "",
    messaggio: "",
    job: title,
    accordoPrivacyCookies: false,
    accordoNewsLetter: false,
    urlPagina: isBrowser && window.location.href,
  })

  const [selectedFile, setSelectedFile] = useState(null)
  const [loading, setLoading] = useState(null)
  const [success, setSuccess] = useState(null)

  const onFileChange = e => {
    // Update the state
    setSelectedFile(e.target.files)
  }

  const sendMail = async event => {
    event.preventDefault()
    setLoading(true)

    const result = await executeRecaptcha("dynamicAction")
    const data = { ...formData, selectedFile: selectedFile }

    if (result)
      new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(selectedFile[0])
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      }).then(file => {
        fetch("/.netlify/functions/sendApplicationMail", {
          body: JSON.stringify({
            file: file,
            ...data,
          }),
          headers: {
            "content-type": "application/json",
          },
          method: "post",
        })
          .then(response => {
            if (response.status === 404) {
              setLoading(false)
              setSuccess(false)
            } else {
              setSuccess(true)
              setLoading(false)

              if (typeof window !== "undefined") {
                window.dataLayer = window.dataLayer || []

                window.dataLayer.push({
                  event: "form_submit_application",
                  formType: "Application",
                  pageURL: window.location.href,
                })
              }
            }
          })
          .catch(error => {
            setLoading(false)
            setSuccess(false)
          })
      })
    else {
      setLoading(false)
      setSuccess(false)
    }
  }

  return (
    <Box sx={{ py: [3, 3, 3, 3] }}>
      <Container name="contactForm">
        <Box>
          <Grid
            columns={["1fr", "1fr", "1fr 1.5fr", "1fr 1fr"]}
            gap={[1, 1, 1, 6]}
          >
            <Box>
              <Heading
                as="h2"
                variant="h1"
                sx={{
                  mt: [0],
                  color: "dark",
                  fontSize: [6],
                  fontWeight: "body",
                  letterSpacing: "-.5px",
                  lineHeight: "1.12",
                  em: {
                    fontStyle: "normal",
                    color: "light",
                  },
                }}
              >
                {title}
              </Heading>
              <Heading
                as="h3"
                variant="h3"
                sx={{ color: "dark", fontWeight: 300 }}
              >
                {subtitle}
              </Heading>
              <Box
                sx={{
                  color: "dark",
                  my: [0, 4],
                }}
                dangerouslySetInnerHTML={{ __html: summary }}
              ></Box>
              <Text
                as="div"
                variant="sectionTitle"
                sx={{
                  my: [4],
                  color: "light",
                  fontSize: [4],
                  fontWeight: 300,
                  letterSpacing: "-.5px",
                  lineHeight: "1.12",
                  em: {
                    fontStyle: "normal",
                    color: "light",
                  },
                }}
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </Box>

            <Box as="form" onSubmit={sendMail} sx={{}} name="send-application">
              <Box>
                <Grid
                  sx={{
                    flexWrap: "wrap",
                    flexDirection: "column",
                  }}
                  gap={[0, 0, 0, 0]}
                >
                  <Flex sx={{ flexWrap: "wrap" }}>
                    <Box
                      sx={{
                        pr: [0, 1, 1, 1],
                        pb: [3, 3, 3, 3],
                        width: ["100%", "50%"],
                      }}
                    >
                      <Input
                        onChange={e =>
                          setFormData(prevState => ({
                            ...prevState,
                            nome: e.target.value,
                          }))
                        }
                        name="nome cognome"
                        placeholder="Nome*"
                        variant="inputs.dark"
                        required
                      />
                    </Box>
                    <Box
                      sx={{
                        pl: [0, 1, 1, 1],
                        pb: [3, 3, 3, 3],
                        width: ["100%", "50%"],
                      }}
                    >
                      <Input
                        onChange={e =>
                          setFormData(prevState => ({
                            ...prevState,
                            cognome: e.target.value,
                          }))
                        }
                        name="cognome"
                        placeholder="Cognome*"
                        variant="inputs.dark"
                        required
                      />
                    </Box>
                  </Flex>
                  <Flex sx={{ flexWrap: "wrap" }}>
                    <Box
                      sx={{
                        pr: [0, 1, 1, 1],
                        pb: [3, 3, 3, 3],
                        width: ["100%", "50%"],
                      }}
                    >
                      <Input
                        onChange={e =>
                          setFormData(prevState => ({
                            ...prevState,
                            email: e.target.value,
                          }))
                        }
                        name="email"
                        type="email"
                        placeholder="Email*"
                        variant="inputs.dark"
                        required
                      />
                    </Box>
                    <Box
                      sx={{
                        pl: [0, 1, 1, 1],
                        pb: [3, 3, 3, 3],
                        width: ["100%", "50%"],
                      }}
                    >
                      <Input
                        onChange={e =>
                          setFormData(prevState => ({
                            ...prevState,
                            telefono: e.target.value,
                          }))
                        }
                        name="telefono"
                        type="text"
                        placeholder="Telefono*"
                        variant="inputs.dark"
                        required
                      />
                    </Box>
                  </Flex>
                  <Box sx={{ pb: 3 }}>
                    <Label for="cv" sx={{ mb: [2, 2, 2, 2] }}>
                      Carica il CV *
                    </Label>
                    <Input
                      variant="inputs.dark"
                      type="file"
                      accept="application/pdf"
                      size="55"
                      id="cv"
                      onChange={onFileChange}
                      required
                    />
                  </Box>

                  <Textarea
                    sx={{
                      fontFamily: "body",
                      fontSize: [2],
                    }}
                    onChange={e =>
                      setFormData(prevState => ({
                        ...prevState,
                        messaggio: e.target.value,
                      }))
                    }
                    name="messaggio"
                    placeholder="Messaggio"
                    variant="inputs.dark"
                    type="textarea"
                  />
                  <LabeledCheckbox
                    checkedCheckbox={e => {
                      setFormData(prevState => ({
                        ...prevState,
                        accordoPrivacyCookies: e,
                      }))
                    }}
                    required={true}
                  >
                    <Box>
                      <Text variant="p" sx={{ fontSize: [0] }}>
                        * Acconsento a che i miei dati personali vengano
                        utilizzati in accordo con la{" "}
                        <InboundLink
                          sx={{ color: "dark", textDecoration: "underline" }}
                          to="/privacy-policy/"
                        >
                          Privacy
                        </InboundLink>{" "}
                        e{" "}
                        <InboundLink
                          sx={{ color: "dark", textDecoration: "underline" }}
                          to="/cookie-policy/"
                        >
                          Cookie Policy
                        </InboundLink>
                      </Text>
                    </Box>
                  </LabeledCheckbox>
                  <LabeledCheckbox
                    checkedCheckbox={e =>
                      setFormData(prevState => ({
                        ...prevState,
                        accordoNewsLetter: e,
                      }))
                    }
                  >
                    <Box>
                      <Text variant="p" sx={{ fontSize: [0] }}>
                        Acconsento all'uso dei miei dati personali per essere
                        aggiornato sui nuovi arrivi, prodotti in esclusiva e per
                        le finalità di marketing diretto correlate ai servizi
                        offerti e ricevere proposte in linea con i miei
                        interessi attraverso l'analisi dei miei precedenti
                        acquisti
                      </Text>
                    </Box>
                  </LabeledCheckbox>
                  {!success && (
                    <Button
                      type="submit"
                      sx={{
                        mt: [3, 3, 3, 3],
                        opacity: !formData.accordoPrivacyCookies && 0.7,
                      }}
                      title={
                        !formData.accordoPrivacyCookies
                          ? "Acconsenti al trattamenti dei dati personali"
                          : ""
                      }
                      variant="buttons.primaryFormEmpty"
                      disabled={loading}
                    >
                      <Flex
                        sx={{
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {loading ? "Caricamento..." : "Invia candidatura"}
                        <Flex
                          variant="svg.arrow.light"
                          sx={{
                            alignItems: "center",
                            alignContent: "center",
                            width: "24px",
                            height: "24px",
                          }}
                        >
                          <ArrowRight />
                        </Flex>
                      </Flex>
                    </Button>
                  )}
                  {success === true ? (
                    <Flex sx={{ maxWidth: "600px" }}>
                      <Heading sx={{ my: [4], color: "primary" }} as="h5">
                        Candidatura inviata!
                      </Heading>
                    </Flex>
                  ) : (
                    success === false && (
                      <Flex sx={{ maxWidth: "600px" }}>
                        <Heading sx={{ my: [4], color: "errorflat" }} as="h5">
                          Qualcosa è andato storto
                        </Heading>
                      </Flex>
                    )
                  )}
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Box>
      </Container>
    </Box>
  )
}

const LabeledCheckbox = ({
  children,
  defaultChecked,
  checkedCheckbox,
  ...props
}) => {
  const [checked, setChecked] = useState(defaultChecked)
  return (
    <Label
      sx={{
        display: "flex",
        alignItems: "center",
        fontWeight: checked ? "bold" : "normal",
        color: "dark",
        svg: {
          color: "dark",
        },
        mt: [3, 3, 3, 3],
      }}
    >
      <Checkbox
        checked={checked}
        onChange={() => {
          checkedCheckbox(!checked)
          setChecked(!checked)
        }}
        {...props}
      />
      {children}
    </Label>
  )
}

export default ApplicationForm
