import React from "react"
import { Box, Flex, Grid, Heading, Text } from "@theme-ui/components"
import { getReferencePath } from "../../utils/path"
import { GatsbyImage } from "gatsby-plugin-image"
import { InboundLink, OutboundLink } from "../link"
import RichContentStructuredText from "../richContentStructuredText"
import ArrowRight from "../../assets/images/arrow-right.svg"

const PageReferenceThumb = ({ reference, small }) => {
  return (
    <Flex
      sx={{ py: [0, 0, 7], flexDirection: ["column-reverse","column-reverse","column-reverse","row"], position: "relative" }}
    >
      <Flex
        sx={{
          width:["100%","50%"],
          flexDirection: "column",
          py: [0],
          pr: [0, 7],
        }}
      >
        <Box>
          <Heading as="div" variant="sectionTitle">
            <Box as="h2" variant="h2">
              {reference.title}
            </Box>
          </Heading>
        </Box>
        <Box>
          <RichContentStructuredText text={reference.body}/>
        </Box>
        <Box sx={{ a: {
          display : "inline-block",
          position:"relative"
        }}}>
          <InboundLink
            to={getReferencePath(reference.link.link, reference.link.locale)}
            sx={{ textDecoration: "none", position: "relative" }}
          >
            <Flex sx={{ alignItems: "center", alignContent: "center" }}>
              <Flex
                sx={{ alignItems: "center", alignContent: "center" }}
                mr={[3]}
              >
                Scopri di più
              </Flex>
              <Flex
                variant="svg.arrow.red"
                sx={{
                  alignItems: "center",
                  alignContent: "center",
                  width: "24px",
                  height: "24px",
                }}
              >
                <ArrowRight />
              </Flex>
            </Flex>
          </InboundLink>
        </Box>
      </Flex>
      <Box
        sx={{
          position: ["relative"],
          width: ["100%","50%"],
        }}
      >
        <Box
          sx={{
            position: ["relative"],
            width: ["100%"],
            maxHeight: ["450px"],
            height:["450px"],
            ".gatsby-image-wrapper": {
              width: "100%",
              height:"100%"
            },
          }}
        >
          {reference.image.gatsbyImageData && <GatsbyImage image={reference.image.gatsbyImageData} alt="" />}
        </Box>
      </Box>
    </Flex>
  )
}

export default PageReferenceThumb
