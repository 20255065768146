import React from "react"
import { Box, Flex, Grid, Container, Text } from "@theme-ui/components"
import { GatsbyImage } from "gatsby-plugin-image"

const SimpleImageTextCollection = ({ items }) => {
  return (
    <Box sx={{ backgroundColor: "lightBackground" }}>
      <Container>
        <Grid
          columns={[2, 2, 3, 4]}
          gap={[16, 16, "32px"]}
          sx={{ py: [0, 0, 7], position: "relative" }}
        >
          {items.map((item, index) => (
            <Box>
              <Box
                sx={{
                  position: ["relative"],
                  width: ["100%"],
                }}
              >
                <Box
                  sx={{
                    position: ["relative"],
                    width: ["100%"],
                    ".gatsby-image-wrapper": {
                      width: "100%",
                    },
                  }}
                >
                  <GatsbyImage image={item.image.gatsbyImageData} alt="" />
                </Box>
              </Box>

              <Flex
                sx={{
                  flexDirection: "column",
                  py: [0],
                  pr: [0, 7],
                }}
              >
                <Box>
                  <Text as="p" sx={{ mt: [3, 3, 3], fontSize: [2, 3, 3] }}>
                    {item.title}
                  </Text>
                </Box>
                <Box>
                  <Text
                    sx={{ pb: [6] }}
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  />
                </Box>
              </Flex>
            </Box>
          ))}
        </Grid>
      </Container>
    </Box>
  )
}

export default SimpleImageTextCollection
