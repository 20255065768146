import React from "react"
import { Box, Container, Text, Heading, Flex } from "@theme-ui/components"
import ArrowRight from "../../assets/images/arrow-right.svg"
import { InboundLink } from "../link"
import { getPagePath } from "../../utils/path"

const WorkWithUsBanner = ({ title, description, link }) => {
  return (
    <Box>
      <Container sx={{ py: [4, 4, 4, 6] }}>
        <Flex
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Box>
              <Heading as="h2" variant="h1" sx={{ fontWeight: "normal" }}>
                {title}
              </Heading>
            </Box>

            <Box
              sx={{ h3: { fontSize: [3, 4], fontWeight: "400" } }}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </Box>
          <Box
            sx={{
              a: {
                textDecoration: "none",
                transition: "all .3s",
                "&:hover": { color: "dark" },
              },
            }}
          >
            <InboundLink to={getPagePath(link.link, link.link.locale)}>
              <Flex
                sx={{
                  alignItems: "center",
                }}
              >
                <Box sx={{ fontSize: [3, 3], mr: [3] }}>
                  Vedi tutte le posizioni aperte
                </Box>
                <Flex
                  variant="svg.arrow.primary"
                  sx={{
                    alignItems: "center",
                    alignContent: "center",
                    width: "48px",
                    height: "48px",
                  }}
                >
                  <ArrowRight />
                </Flex>
              </Flex>
            </InboundLink>
          </Box>
        </Flex>
      </Container>
    </Box>
  )
}

export default WorkWithUsBanner
