import React, { useState } from "react"
import { Box, Button, Container, Flex, Heading } from "@theme-ui/components"
import ArrowRight from "../../assets/images/arrow-right.svg"
import {
  Accordion as AccordionWrapper,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion"
import ReactModal from "react-modal"
import ApplicationForm from "./applicationForm"
import { useBreakpointIndex } from "@theme-ui/match-media"

const JobsCollection = ({ title, items }) => {
  const [open, setOpen] = useState(false)
  const [selectedJob, setSelectedJob] = useState(items[0].title)
  const index = useBreakpointIndex()
  const modalWidth = {
    0: "90vw",
    1: "90vw",
    2: "90vw",
    3: "70vw",
  }

  ReactModal.defaultStyles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      zIndex: 1001,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    content: {
      width: modalWidth[index],
      padding: "0 !important",
      background: "#ffffff",
      overflow: "auto",
      WebkitOverflowScrolling: "touch",
      borderRadius: "4px",
      outline: "none",
      maxHeight: "80vh",
    },
  }

  return (
    <Box mb={[3, 5]}>
      <Container>
        <Heading as="h2" variant="h1" sx={{ fontWeight: "normal" }}>
          {title}
        </Heading>
      </Container>
      <Container>
        <AccordionWrapper allowMultipleExpanded={true} allowZeroExpanded={true}>
          {items.map((item, index) => (
            <Box>
              <AccordionItem key={item.id}>
                <AccordionItemState>
                  {({ expanded }) => (
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <Flex
                          sx={{
                            borderTop: "1px solid",
                            borderColor: "primary",
                            cursor: "pointer",
                            "&:hover": {
                              h3: {
                                transform: "translateX(32px)",
                              },
                            },
                            py: [3, 4, 4, 5],
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Heading
                            as="h3"
                            variant="h3"
                            sx={{
                              transform: expanded
                                ? "translateX(32px)"
                                : "inherit",
                              my: [0, 0, 0, 0],
                              transition: "0.2s ease-in-out",
                            }}
                          >
                            {item.title}
                          </Heading>
                          <Box
                            sx={{
                              svg: {
                                transform: expanded
                                  ? "rotate(90deg)"
                                  : "inherit",
                                transition: "0.2s ease-in-out",
                              },
                            }}
                          >
                            <ArrowRight />
                          </Box>
                        </Flex>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                  )}
                </AccordionItemState>
                <AccordionItemPanel
                  style={{ animation: "fadein 0.35s ease-in" }}
                >
                  <Container sx={{ paddingTop: [0, 0, 0] }}>
                    <Box
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                    <Button
                      onClick={() => {
                        setSelectedJob(item.title)
                        setOpen(true)
                      }}
                      sx={{
                        fontFamily: "body",
                        borderRadius: 0,
                        fontSize: [2, 2, 2, 2],
                        px: [2, 3, 3, 4],
                        py: [2, 2, 2, 3],
                        my: [2, 2, 3, 3],
                      }}
                    >
                      Invia candidatura
                    </Button>
                  </Container>
                </AccordionItemPanel>
              </AccordionItem>
            </Box>
          ))}
        </AccordionWrapper>
      </Container>
      <ReactModal
        isOpen={open}
        onRequestClose={() => setOpen(false)}
        ariaHideApp={false}
        closeTimeoutMS={500}
      >
        <ApplicationForm
          title={selectedJob}
          subtitle={"Invia la tua candidatura"}
        />
      </ReactModal>
    </Box>
  )
}

export default JobsCollection
