import React from "react"
import { Box, Container, Heading, Text, Grid } from "@theme-ui/components"

const ProductFeatures = ({ block }) => {
  console.log(block)
  return (
    <Container sx={{ my: [0] }}>
      <Grid columns={[1, 2, 3]}>
        {block.features.map((feature, index) => (
          <Box>
            <Heading as="div" variant="sectionTitle">
              <Box as="h2">{feature.title}</Box>
            </Heading>
            <Box
              sx={{ letterSpacing: "-0.47px", lineHeight: "1.4", pr: [2] }}
              dangerouslySetInnerHTML={{ __html: feature.subtitle }}
            />
          </Box>
        ))}
      </Grid>
    </Container>
  )
}

export default ProductFeatures
