import React from "react"
import { Box, Container, Flex, Heading, Text, Grid } from "@theme-ui/components"

// Begin swiper
import SwiperCore, { Pagination, Mousewheel, A11y } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { InboundLink } from "../link"
import { getPagePath } from "../../utils/path"
import { GatsbyImage } from "gatsby-plugin-image"

SwiperCore.use([Mousewheel, Pagination, A11y])
// End swiper

const PageCarousel = ({ title, pages }) => {
  // console.log(pages)
  return (
    <Box>
      <Container>
        <Grid columns={[3]} gap={[4]}>
          {pages.map(page => (
            <PageThumb page={page} />
          ))}
        </Grid>
      </Container>
    </Box>
  )
}

const PageThumb = ({ page }) => (
  <InboundLink
    to={getPagePath(page, page.locale)}
    sx={{ textDecoration: "none" }}
  >
    <Box
      sx={{
        width: "100%",
        height: "100%",
        position: "relative",
        ".gatsby-image-wrapper": {
          position: "absolute",
          top: 0,
          left: 0,
          width: ["100%", "100%"],
          zIndex: 0,
          height: ["100%", "100%"],
        },
      }}
    >
      {page.heroImage && (
        <GatsbyImage image={page.heroImage.gatsbyImageData} alt="" />
      )}
      <Flex
        sx={{ height: ["24rem"], padding: 4, zIndex: 0, position: "relative" }}
      >
        <Heading sx={{ margin: 0, color: "light" }}>{page.title}</Heading>
      </Flex>
    </Box>
  </InboundLink>
)

export default PageCarousel
