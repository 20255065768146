import React, { useState } from "react"
import {
  Container,
  Link,
  Box,
  Flex,
  Grid,
  Heading,
  Text,
} from "@theme-ui/components"
import { GatsbyImage } from "gatsby-plugin-image"
// Begin swiper
import SwiperCore, { Navigation, Pagination, Mousewheel, A11y } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import RichContentStructuredText from "../richContentStructuredText"
import { motion, AnimatePresence } from "framer-motion"
import { wrap } from "popmotion"
import ArrowLeft from "../../assets/images/arrow-left.svg"
import ArrowRight from "../../assets/images/arrow-right.svg"
import { useBreakpointIndex } from "@theme-ui/match-media"

SwiperCore.use([Mousewheel, Pagination, A11y, Navigation])
// End swiper

const variants = {
  enter: direction => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    }
  },
  exit: direction => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    }
  },
}

const swipeConfidenceThreshold = 10000
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity
}

const StaffCarousel = ({ staff, kicker }) => {
  const [[page, direction], setPage] = useState([0, 0])

  const activeItem = wrap(0, staff.length, page)

  const paginate = newDirection => {
    setPage([page + newDirection, newDirection])
  }
  
  const breakpointIndex = useBreakpointIndex()

  return (
    <Box sx={{ my: [0], backgroundColor: "primary", position: "relative" }}>
      <Grid
        columns={["1fr","1fr","1fr","1fr 1fr"]}
        gap={[0]}
        sx={{
          position: "absolute",
          top: 0,
          width: "100%",
          height: "100%",
          zIndex: 0,
          overflow: "hidden",
        }}
      >
        {breakpointIndex > 2 && <Box sx={{ backgroundColor: "primary" }}></Box>}
        <Box
          sx={{
            width: "100%",
            ".gatsby-image-wrapper": {
              width: "100%",
              height: "100%",
              "&::after": {
                "content": "'\A'",
                position: "absolute",
                width: "100%",
                height:"100%",
                top:0,
                left:0,
                background:"rgba(0,0,0,0.4)",
                opacity: breakpointIndex > 2 ? 0 : 1,
                "transition": "all 1s",
                "-webkit-transition": "all 1s",
              }
            },
          }}
        >
          <GatsbyImage image={staff[activeItem].image.gatsbyImageData} alt="" />
        </Box>
      </Grid>

      <Container
        sx={{
          overflow: "hidden",
          position: "relative",
        }}
      >
        <Box
          sx={{ zIndex: 1, position: "relative", display: "block", pb: [5] }}
        >
          <Text as="div" variant="sectionTitle" color="light">
            <Flex as="div" className="light">
              <span>{kicker}</span>
            </Flex>
          </Text>
        </Box>
        <Box sx={{ width: "100%", height: "100%", minHeight:"550px", position: "relative" }}>
          <Box
            sx={{
              display: "flex",
              position: "relative",
            }}
          >
            <Flex
              onClick={() => paginate(-1)}
              variant="svg.arrow.light"
              sx={{
                mr: [2],
                alignItems: "center",
                alignContent: "center",
                width: "48px",
                height: "48px",
              }}
            >
              <ArrowLeft />
            </Flex>
            <Flex
              onClick={() => paginate(1)}
              variant="svg.arrow.light"
              sx={{
                alignItems: "center",
                alignContent: "center",
                width: "48px",
                height: "48px",
              }}
            >
              <ArrowRight />
            </Flex>
          </Box>
          {staff.map(item => (
            <Item richContent={item} active={staff[activeItem] === item} />
          ))}
        </Box>
      </Container>
    </Box>
  )
}

const Item = ({ richContent, active }) => (
  <Box sx={{ display: active ? "block" : "none", minHeight: ["auto","500px"] }}>
    <Grid
      columns={[1,1,1, 2]}
      gap={1}
      sx={{
        display: active,
        height: "100%",
        position: "relative",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        gridTemplateRows: ["1fr"],
      }}
    >
      <Flex
        sx={{
          py: [3, 3, 3, 5],
          px: 0,
          flexDirection: "column",
          overflow: "hidden",
          minHeight: ["40vh", "40vh"],
        }}
      >
        <Box sx={{ overflow: "hidden", width: "100%", mt: 4, pr: [5] }}>
          <motion.div
            initial={{ y: "-100%" }}
            animate={{
              x: "0",
              y: "0",
              transition: {
                delay: 0.8,
              },
            }}
            exit={{ x: "100%" }}
            transition={{ delay: 0.3, duration: 0.3 }}
          >
            <Box>
              <Heading
                sx={{
                  mb: 1,
                  fontSize: [6, 6, 8, 8],
                  fontWeight: 500,
                  display: "inline",
                }}
                variant="h2"
                color="light"
              >
                {richContent.subtitle}
              </Heading>
            </Box>
            <Box sx={{ py: [4] }}>
              <Heading
                sx={{
                  mb: 1,
                  display: "inline",
                }}
                variant="h2"
                color="light"
              >
                {richContent.label}
              </Heading>
            </Box>
            <Box
              sx={{
                mb: [4],
                p: { pr: [0,0,0,10], color: "light", lineHeight: "20px" },
              }}
            >
              <RichContentStructuredText text={richContent.body} />
            </Box>
          </motion.div>
        </Box>
      </Flex>
    </Grid>
  </Box>
)

export default StaffCarousel
