import React, { useEffect, useState } from "react"
import { Box, Text, Heading, Flex, Container, Grid } from "@theme-ui/components"
import CountUp from "react-countup"
import { useInView } from "react-intersection-observer"
import { GatsbyImage } from "gatsby-plugin-image"
import { getColor } from "@theme-ui/color"
import themeUiTheme from "../../gatsby-plugin-theme-ui"
import { Plus } from "react-feather"
import { useBreakpointIndex } from "@theme-ui/match-media"
const NumbersCollection = ({ title, image, numbers }) => {
  const breakpointIndex = useBreakpointIndex()
  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          height: "100%",
          width: "100%",
          zIndex: 0,
        }}
      >
        <Box
          sx={{
            height: "100%",
            width: "100%",
            backgroundColor: "dark",
            ".gatsby-image-wrapper": {
              height: "100%",
              width: "100%",
            },
          }}
        >
          {image && image.gatsbyImageData && (
            <GatsbyImage image={image.gatsbyImageData} alt="" />
          )}
        </Box>
      </Box>

      <Container sx={{ position: "relative", py: [7, 9, 9, 8] }}>
        <Grid sx={{ flexWrap: "wrap" }} columns={["1fr", 2, 4]}>
          {numbers.map(number => (
            <Number number={number} />
          ))}
        </Grid>
      </Container>
    </Box>
  )
}

const Number = ({ number }) => {
  const primary = getColor(themeUiTheme, "primary")
  const [startCount, setStartCount] = useState(false)
  const [ref, inView] = useInView()
  useEffect(() => {
    if (inView === true && startCount === false) {
      setStartCount(true)
    }
  }, [inView])

  return (
    <Box ref={ref}>
      <Flex sx={{ alignItems: "center" }}>
        <Box sx={{ pr: [4, 0], minWidth: "40px", width: "40px" }}>
          <Plus size={38} color={primary} />
        </Box>
        <Heading
          as="h4"
          variant="h4"
          sx={{ mt: 0, mb: 0, pr: [3, 9], py: [3, 0], color: "light" }}
        >
          <CountUp
            end={number.float}
            suffix={number.prefix ? ` ${number.prefix}` : null}
            start={startCount}
          />
          <Box as="p" sx={{}}>
            {number.suffix}
          </Box>
        </Heading>
      </Flex>
    </Box>
  )
}

export default NumbersCollection
