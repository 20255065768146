import React from "react"
import { Box, Container, Grid, Heading, Text, Flex } from "@theme-ui/components"
import { GatsbyImage } from "gatsby-plugin-image"
import FamilyThumb from "../familyThumb"
import RichContentStructuredText from "../richContentStructuredText"
import { MagicLink } from "../../utils/magicLink"
import { InboundLink } from "../link"
import { getSectorPath, getNewsPath } from "../../utils/path"
import ArrowRight from "../../assets/images/arrow-right.svg"
import NewsSectionArticleCard from "../newsSectionArticleCard"

const IconsAndText = ({ kicker, title, description, icons }) => {
  return (
    <Box>
      <Container sx={{ my: [0] }}>
        <Box>
          <Text as="div" variant="sectionTitle">
            <Flex as="div">
              <span>{kicker}</span>
            </Flex>
          </Text>
        </Box>
        <Box sx={{ pt: [6,7] }}>
          <Grid columns={["1fr", ".25fr .75fr"]} gap={[8]}>
            <Box>
              <Heading as="div" variant="sectionTitle">
                <Box as="h2">{title}</Box>
              </Heading>
              <Text
                as="p"
                sx={{ letterSpacing: "-0.47px", lineHeight: "1.4", pr: [2] }}
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </Box>
            <Box sx={{ py: [4] }}>
              <Box as="ul" sx={{ p: [0] }}>
                {icons.map(icon => (
                  <Box
                    as="li"
                    sx={{
                      listStyle: "none",
                      py: [3],
                      borderTop: "1px solid",
                      borderColor: "primary",
                      ":last-child": {
                        borderBottom: "1px solid",
                        borderColor: "primary",
                      },
                    }}
                  >
                    <Flex
                      sx={{
                        alignItems: "center",
                        justifyContent: "space-between!important",
                      }}
                    >
                      <Flex sx={{ alignItems: "center" }}>
                        <Box sx={{ pr: [6,4] }}>
                          {icon.image && icon.image.url && (
                            <Box
                              sx={{
                                backgroundColor: "dark",
                                mask: `url(${icon.image.url}) no-repeat`,
                                "-webkit-mask": `url(${icon.image.url}) no-repeat`,
                                "mask-size": "contain",
                                "-webkit-mask-size": "contain",
                                width: "55px",
                                height: "55px",
                              }}
                            ></Box>
                          )}
                        </Box>
                        <Heading as="h4" variant="h4" sx={{ m: 0 }}>
                          {icon.title}
                        </Heading>
                      </Flex>
                    </Flex>
                  </Box>
                ))}
              </Box>
            </Box>
          </Grid>
        </Box>
      </Container>
    </Box>
  )
}

export default IconsAndText
