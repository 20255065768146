import React from "react"
import { Box, Container, Heading } from "@theme-ui/components"
import PageSubtitle from "../pageSubtitle"
import SocafGridList from "./socafGridList.js"
const SocafProducts = ({ block, products, categories, familyCategories }) => {
  return (
    <Box>
      <Container sx={{ pt: "0px!important" }}>
        <Heading variant="h3" sx={{ mb: 5 }}>
          {block.title}
        </Heading>
        <SocafGridList
          products={products}
          categories={categories}
          familyCategories={familyCategories}
        />
      </Container>
    </Box>
  )
}

export default SocafProducts
