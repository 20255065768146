import React from "react"
import { Box, Grid, Heading } from "@theme-ui/components"
import { GatsbyImage } from "gatsby-plugin-image"
import ProductThumb from "../productThumb"
import SocafProductThumb from "./socafProductThumb"
const SocafGridList = ({ products, categories, familyCategories }) => {
  console.log(products)
  return (
    <Box>
      <Grid columns={[1, 2, 2, 3]} gap={[4]}>
        {products.map(product => (
          <Box key={product.id}>
            <ProductThumb
              product={product}
              parentCategory={categories}
              parentFamily={familyCategories}
            />
          </Box>
        ))}
      </Grid>
    </Box>
  )
}

export default SocafGridList
