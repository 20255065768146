import React from "react"
import { Box, Container, Grid, Heading, Text, Flex } from "@theme-ui/components"
import { GatsbyImage } from "gatsby-plugin-image"
import FamilyThumb from "../familyThumb"
import RichContentStructuredText from "../richContentStructuredText"
import { MagicLink } from "../../utils/magicLink"
import { InboundLink } from "../link"
import { getSectorPath, getNewsPath } from "../../utils/path"
import ArrowRight from "../../assets/images/arrow-right.svg"
import NewsSectionArticleCard from "../newsSectionArticleCard"
import { getColor } from "@theme-ui/color"
import themeUiTheme from "../../gatsby-plugin-theme-ui"
import { useBreakpointIndex } from "@theme-ui/match-media"
import { Plus } from "react-feather"

const IconBanner = ({ background, icons }) => {
  const primary = getColor(themeUiTheme, "primary")
  const breakpointIndex = useBreakpointIndex()

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          height: "100%",
          width: "100%",
          zIndex: 0,
        }}
      >
        <Box
          sx={{
            height: "100%",
            width: "100%",
            ".gatsby-image-wrapper": {
              height: "100%",
              width: "100%",
            },
          }}
        >
          {background && background.gatsbyImageData && (
            <GatsbyImage image={background.gatsbyImageData} alt="" />
          )}
        </Box>
      </Box>
      <Container sx={{ position: "relative", py: [8, 9, 9, 10] }}>
        <Grid columns={[1,2,3,icons.length > 4 ? 4 : icons.length ]} sx={{ rowGap: [8] }}>
          {icons.map(icon => (
            <Flex>
              <Box>
                <Plus size={38} color={primary} />
              </Box>
              <Box sx={{ pl: [4] }}>
                <Heading as="h4" variant="h4" sx={{ mt: 0, color: "light" }}>
                  {icon.title}
                </Heading>
                <Box
                  sx={{
                    p: {
                      color: "light",
                    },
                  }}
                >
                  <RichContentStructuredText text={icon.body} />
                </Box>
              </Box>
            </Flex>
          ))}
        </Grid>
      </Container>
    </Box>
  )
}

export default IconBanner
