import React from "react"
import { Box, Flex, Grid, Heading, Text, Container } from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"
import { GatsbyImage } from "gatsby-plugin-image"
import { MagicLink } from "../../utils/magicLink"

const ImageAndText = ({ title, body, image, label, link, backgroundColor }) => {
  return (
    <Box
      sx={{
        backgroundColor: backgroundColor ? backgroundColor.hex : "transparent",
        my: backgroundColor ? [0, 0, 0] : [4],
      }}
    >
      <Container sx={{ py: [2, 3, 4] }}>
        <Box>
          <Flex
            sx={{
              color: backgroundColor ? "light" : "dark",
              borderBottom: "1px solid",
              borderColor: backgroundColor ? "light !important" : "dark",
              pb: [2],
            }}
          >
            <span>{label}</span>
          </Flex>
        </Box>
        <Grid
          columns={[1, 1, 2]}
          gap={[0, 0, 0]}
          sx={{ py: [7, 7, 7], position: "relative" }}
        >
          <Flex
            sx={{
              flexDirection: "column",
              pr: [0, 7],
            }}
          >
            <Box>
              <Heading as="div" variant="sectionTitle">
                <Box
                  as="h2"
                  variant="h2"
                  sx={{ color: backgroundColor ? "light" : "dark" }}
                >
                  {title}
                </Box>
              </Heading>
              <Box
                sx={{
                  p: {
                    color: "dark",
                    letterSpacing: "-0.47px",
                    lineHeight: "1.4",
                    pr: [0, 4],
                    color: backgroundColor ? "light" : "dark",
                  },
                }}
              >
                <RichContentStructuredText text={body} theme="dark" />
                {link && (
                  <Box
                    sx={{
                      a: {
                        padding: [3],
                        border: "1px solid",
                        borderColor: backgroundColor ? "light" : "primary",
                        color: backgroundColor ? "light" : "primary",
                        mt: [2, 3, 4],
                        display: "inline-block",
                        textDecoration: "none",
                      },
                    }}
                  >
                    <MagicLink item={link} locale={link.locale} />
                  </Box>
                )}
              </Box>
            </Box>
          </Flex>
          <Box
            sx={{
              position: ["relative"],
              width: ["100%"],
            }}
          >
            <Box
              sx={{
                position: ["relative"],
                width: ["100%"],
                ".gatsby-image-wrapper": {
                  width: "100%",
                },
              }}
            >
              <GatsbyImage image={image.gatsbyImageData} alt="" />
            </Box>
          </Box>
        </Grid>
      </Container>
    </Box>
  )
}

export default ImageAndText
